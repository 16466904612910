import IMask from 'imask';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('input[type=tel]')) {
    telMasksTrigger();
  }

  if (document.querySelector('[data-copy]')) {
    clickCopy();
  }

  if (document.querySelector('#map')) {
    setTimeout(() => {
      addMapScripts();
    }, 3000);
  }

  if (document.querySelector('.menu-trigger')) {
    document.querySelector('.menu-trigger').addEventListener('click', menuOpen);
  }

  if (document.querySelector('.menu-close')) {
    document.querySelector('.menu-close').addEventListener('click', menuClose);
  }

  if (document.querySelector('[data-pricetarget]')) {
    priceAcc();
  }

  headerStick();

  const sliderPromo = new Swiper('.slider-promo', {
    pagination: {
      el: '.swiper-pagination',
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      280: {
        slidesPerView: 'auto',
        spaceBetween: 8,
      },
      400: {
        slidesPerView: 'auto',
        spaceBetween: 8,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  });

  const sliderAbout = new Swiper('.slider-about', {
    spaceBetween: 20,
    slidesPerView: 3,

    pagination: {
      el: '.swiper-pagination',
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      280: {
        slidesPerView: 'auto',
        spaceBetween: 8,
      },
      400: {
        slidesPerView: 2,
        spaceBetween: 8,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  createScrollbarWidth();
});

function telMasksTrigger() {
  document.querySelectorAll('input[type=tel]').forEach((el) => {
    window.telArr = [];

    window.telArr.push(
      IMask(el, {
        mask: '+{7} 000 000 00 00',
      })
    );
  });
}

function clickCopy() {
  document.querySelectorAll('[data-copy]').forEach((el) => {
    el.addEventListener('click', copyClickedData);
  });
}

function copyClickedData(e) {
  const data = e.currentTarget.dataset.copy;

  navigator.clipboard.writeText(data);
}

function addMapScripts() {
  const script1 = document.createElement('script');
  const script2 = document.createElement('script');

  script1.defer = true;
  script2.defer = true;

  script1.src =
    'https://api-maps.yandex.ru/2.1/?apikey=9e9fa8b4-3d73-4c40-9ab9-dc581593e696&lang=ru_RU';
  script2.src = 'assets/js/map.js';

  document.body.appendChild(script1);

  script1.addEventListener('load', () => document.body.appendChild(script2));
}

function headerStick() {
  const headerEl = document.querySelector('.header');

  document.addEventListener('scroll', () => {
    if (window.scrollY > 0) {
      headerEl.classList.add('moved');
    } else {
      headerEl.classList.remove('moved');
    }
  });
}

function menuOpen() {
  doFreezePage();
  document.querySelector('.nav-mobile').classList.add('active');
}

function menuClose() {
  document.querySelector('.nav-mobile').classList.remove('active');
  undoFreezePage();
}

function doFreezePage() {
  const html = document.querySelector('html');

  createScrollbarWidth();
  html.classList.add('freeze');
}
function undoFreezePage() {
  const html = document.querySelector('html');

  createScrollbarWidth();
  html.classList.remove('freeze');
}

function createScrollbarWidth() {
  const html = document.querySelector('html');
  const scrollDiv = document.createElement('div');
  scrollDiv.style.cssText =
    'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;';
  document.body.appendChild(scrollDiv);
  const scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  html.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`);
}

function priceAcc() {
  document.querySelectorAll('[data-pricetarget]').forEach((el) => {
    el.addEventListener('click', togglePriceAcc);
  });
}

function togglePriceAcc(e) {
  const target = e.currentTarget.dataset.pricetarget;

  e.currentTarget.classList.toggle('active');

  document.querySelectorAll(`[data-pricecontent="${target}"]`).forEach((el) => {
    el.classList.toggle('active');
  });
}
